export type ENV = `localhost` | `cmddev` | `cmdtest`;

export const LOCALHOST = `localhost`;
export const IDENTITY = `identity`;
export const API = `api`;
export const LUNA_GENERIC = `luna-generic`;
export const SOURCE_APPLICATION_ID = 'appspacepreference';

export const LUNA_CMDDEV_HOST = `hyperbridge-xpanse.cmddev.thermofisher.com`;
export const MYLIBRARY_CMDDEV_HOST = `mylibrary.cmddev.thermofisher.com`;
export const IDENTITY_CMDDEV = `https://identity.hyperbridge-xpanse.cmddev.thermofisher.com`;
export const IDENTITY_CMDDEV_HOST = `identity.hyperbridge-xpanse.cmddev.thermofisher.com`;
export const API_XPANSE = `https://api.hyperbridge-xpanse.cmddev.thermofisher.com`;
export const API_NUCLEUS = `https://api.hyperbridge-nucleus.cmddev.thermofisher.com`;
export const API_CMDDEV_HOST = `api.hyperbridge-xpanse.cmddev.thermofisher.com`;

export const ADMIN_REGISTERED = `/user-management/api/v1/user/isAdminRegistered`;
export const USER_CREATE = `/user-management/api/v1/user`;
export const USER_RESEND_EMAIL = (emailId) =>
  `/user-management/api/v1/email/EmailLink?email=${emailId}`;

export const REGISTRATION_API_ROOT = `/identity-registration/api`;

export const IDENTITY_LIST = `/IdentityProviderRegistration`;
export const IDENTITY_LIST_BY_NAME = `/IdentityProviderRegistration/identityproviderbyname`;
export const IDENTITY_ADD = `/IdentityProviderRegistration`;
export const IDENTITY_ADD_INITIAL = `${IDENTITY_ADD}/initial`;
export const IDENTITY_UPDATE = `/IdentityProviderRegistration`;
export const IDENTITY_DELETE = `/IdentityProviderRegistration`;
export const IDENTITY_PROVIDER_TYPES = `/IdentityProviderRegistration/providerType`;

export const CLIENT_REGISTRATION = '/identity-registration/api/v2';
export const CLIENT_REGISTRATION_NEWCODE = `${CLIENT_REGISTRATION}/Clients`;
export const REGISTRATIONS = `${CLIENT_REGISTRATION}/Clients/registrations`;
export const CLIENT_REGISTRATION_ENABLE = `${CLIENT_REGISTRATION}/Clients/registrations`;

///Get All applications
export const APPSPACE_GET_ALL_APPLICATIONS = () =>
  //`/appspace-management/api/v1/appspace/applications`;
  `/appspace-management/api/v2/homepage`;

//Get All widgets
export const APPSPACE_GET_ALL_WIDGETS = () =>
  `/appspace-management/api/v1/appspace/home`;

//Get widget Icon URL
export const APPSPACE_GET_WIDGET_ICON_URL = (
  appId: string,
  widgetId: string,
  wIconUrl: string
) =>
  `/appspace-management/api/v1/applications/${appId}/widgets/${widgetId}/${wIconUrl?.toLowerCase()}`;

//Get application Icon URL
export const APPSPACE_GET_APPLICATION_ICON_URL = (
  appId: string,
  appIconURL: string
) =>
  `/appspace-management/api/v1/applications/${appId}/${appIconURL?.toLowerCase()}`;

//Get/Set AppSpace platform user preferences, as whole AppSpace preferences not application or widget specific
export const APPSPACE_USER_PREFERENCES = (_sourceId: string) =>
  `/appspace-management/api/v1/${_sourceId}/userPreference`;

//Get pat token
export const APPSPACE_PAT_TOKEN = () => `/tokenApi/pat`;

//Save Widget preferences
export const APPSPACE_SAVE_ALL_WIDGETS = () =>
  `/appspace-management/api/v1/userWidgetPreferenceCollections`;

//Save Application preferences
export const UPDATE_APPLICATION_USER_PREFERENCES = (_applicationId: string) =>
  `/appspace-management/api/v1/applications/${_applicationId}/${SOURCE_APPLICATION_ID}/userApplicationPreference`;
