/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  APPSPACE_GET_ALL_APPLICATIONS,
  APPSPACE_GET_ALL_WIDGETS,
  UPDATE_APPLICATION_USER_PREFERENCES,
  APPSPACE_SAVE_ALL_WIDGETS,
  APPSPACE_USER_PREFERENCES,
  APPSPACE_PAT_TOKEN
} from '../constants/api-url-contants';
import {
  ApplicationDataService,
  fetchThroughBff
} from '@thermo/appspace-library';
export class AppDataService {
  private static _instance: AppDataService;

  public static get Instance(): AppDataService {
    if (!AppDataService._instance) {
      AppDataService._instance = new AppDataService();
    }
    return AppDataService._instance;
  }

  getRequestHeaders() {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
  }

  getRequestPATTokenHeaders() {
    return {
      credentials: 'include',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      applicationCode: 'z78ja2c2',
      'x-csrf': '1'
    };
  }

  async getPATToken(): Promise<Response> {
    const url =
      ApplicationDataService.Instance.getApiURL() + APPSPACE_PAT_TOKEN();
    return fetchThroughBff(url, {
      method: 'get',
      headers: this.getRequestPATTokenHeaders()
    })
      .then(this._handleErrors)
      .then(async (res: Response) => {
        return res.ok ? await res.json() : [];
      });
  }

  private async _handleErrors(response: Response): Promise<Response> {
    if (!response.ok) {
      const msg = await response.text();
      const err: Error = { name: response.statusText, message: msg };
      throw err;
    }
    return response;
  }

  async getUserApplications(): Promise<Response> {
    const url =
      ApplicationDataService.Instance.getApiURL() +
      APPSPACE_GET_ALL_APPLICATIONS();
    return fetchThroughBff(url, {
      method: 'get',
      headers: this.getRequestHeaders()
    })
      .then(this._handleErrors)
      .then(async (res: Response) => {
        return res.ok ? await res.json() : [];
      });
  }

  async getUserWidgets(): Promise<Response> {
    const url =
      ApplicationDataService.Instance.getApiURL() + APPSPACE_GET_ALL_WIDGETS();
    return fetchThroughBff(url, {
      method: 'get',
      headers: this.getRequestHeaders()
    })
      .then(this._handleErrors)
      .then(async (res: Response) => {
        return res.ok ? await res.json() : [];
      });
  }

  async getAppSpaceUserPreferences(
    sourceId = 'appspacepreference'
  ): Promise<any> {
    const url =
      ApplicationDataService.Instance.getApiURL() +
      APPSPACE_USER_PREFERENCES(sourceId);
    return fetchThroughBff(url, {
      method: 'get',
      headers: this.getRequestHeaders()
    })
      .then(this._handleErrors)
      .then(async (res: Response) => {
        if (res.ok) {
          return await res.json();
        } else {
          return [];
        }
      });
  }

  async saveUserWidgetData(data): Promise<any> {
    const url =
      ApplicationDataService.Instance.getApiURL() + APPSPACE_SAVE_ALL_WIDGETS();
    return fetchThroughBff(url, {
      method: 'put',
      headers: this.getRequestHeaders(),
      body: JSON.stringify(data)
    })
      .then(this._handleErrors)
      .then(async (res: Response) => {
        return res.ok ? await res : [];
      });
  }

  async updateAppSpaceUserPreferences(
    preference,
    sourceId = 'appspacepreference'
  ): Promise<any> {
    const url =
      ApplicationDataService.Instance.getApiURL() +
      APPSPACE_USER_PREFERENCES(sourceId);
    return fetchThroughBff(url, {
      method: 'put',
      body: JSON.stringify(preference),
      headers: this.getRequestHeaders()
    })
      .then(this._handleErrors)
      .then(async (res: Response) => {
        return res.ok;
      });
  }

  async updateApplicationUserPreferences(
    applicationId,
    preference
  ): Promise<any> {
    const url =
      ApplicationDataService.Instance.getApiURL() +
      UPDATE_APPLICATION_USER_PREFERENCES(applicationId);
    return fetchThroughBff(url, {
      method: 'put',
      body: JSON.stringify(preference),
      headers: this.getRequestHeaders()
    })
      .then(this._handleErrors)
      .then(async (res: Response) => {
        return res.ok;
      });
  }
}
